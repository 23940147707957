<template>
  <div class="h-screen w-screen grid grid-cols-12 bg-grey-50">
    <div
      class="lg:col-span-6 col-span-full px-8 py-32 lg:p-32 grid content-center relative h-screen"
    >
      <div class="flex justify-center items-center">
        <v-card
          class="p-5 shadow-2xl rounded-lg"
          max-width="400"
          min-width="300"
          outlined
        >
          <div>
            <v-card-actions>
              <v-form ref="form" class="w-full" v-model="valid" lazy-validation>
                <v-text-field
                  v-model="phoneModel"
                  outlined
                  :rules="[rules.required]"
                  label="Nhập số điện thoại"
                  color="primary"
                  width="100%"
                  @click="() => (isInputPhone = true)"
                />
                <v-btn
                  :disabled="!valid"
                  large
                  color="primary"
                  class="w-full rounded-md"
                  @click="validate"
                >
                  Xem đơn thuốc
                </v-btn>
              </v-form>
            </v-card-actions>
          </div>
        </v-card>
      </div>
      <span
        class="text-xs text-center text-grey bottom-4 left-0 right-0 mx-auto absolute"
      >
        Copyright © 2022 - 2023<br />
        Bản quyền thuộc về Bảo Đại Đường
      </span>
    </div>
    <div class="lg:col-span-6 hidden lg:block">
      <img class="h-screen w-full" src="@/assets/imgs/login-background.png" alt="" />
    </div>
  </div>
</template>

<script>
import { required } from "~/utils/validation";
import { SESSION_STORAGE_KEY } from "~/utils/constants";

export default {
  name: "Verification",
  metaInfo() {
    return {
      title: "Đăng nhập | Hệ thống quản lý",
    };
  },
  data() {
    return {
      phoneModel: null,
      valid: true,
      rules: {
        required,
      },
    };
  },
  methods: {
    async validate() {
      await this.$refs.form.validate();
      if (this.valid) {
        const prescriptionId = sessionStorage.getItem(
          SESSION_STORAGE_KEY.PRESCRIPTION_ID
        );
        if (!prescriptionId) {
          this.$toasted.error("Scan QR để xem thông tin chi tiết đơn thuốc").goAway(2000);
          return;
        }
        const response = await this.$services.commonService.prescriptionInfo({
          phone: this.phoneModel,
          id: prescriptionId,
        });
        this.$store.commit("prescriptionForPatient/setData", response);
        this.$router.push({ name: "PrescriptionForPatient" });
      }
    },
  },
};
</script>
